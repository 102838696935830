@import 'styles/variables';
@import 'styles/mixins';

.row {
  display: flex;
  align-items: center;
}

.column {
  @include for-mobile {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
  }
}

.inputBox {
  display: flex;
  align-items: center;
  box-shadow: 0 0 17px rgba($colorDarkBlue, 0.1);
  border-radius: 16px;
  width: 100%;
  min-height: 56px;
  justify-content: space-between;
  position: relative;

  &_repay {
    min-height: 58px;

    @include for-mobile {
      min-height: 48px;
    }
  }

  &_exchangeLayout {
    border: 0.5px solid $colorLineLightBlue;
  }

  @include for-mobile() {
    &_exchangeLayout {
      min-height: 100%;
    }
  }
}

.inputField {
  width: calc(100% - 45px);
  height: 100%;
  font-size: $mediumFontSize;
  line-height: 24px;
  color: $colorTextBlackMain;
  border: none;
  transition: all 0.3s ease 0s;
  border-radius: 12px;

  @include for-mobile {
    width: 100%;
  }

  &_repay {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 0;

    @include for-mobile {
      font-size: 18px;
    }
  }

  &.exchangeLayout {
    @include for-mobile {
      max-height: 50px;
    }
  }
}

.inputFieldOpened {
  &.exchangeLayout {
    border-radius: inherit;
  }
}

.inputBoxOpened {
  border-radius: 16px 16px 0 16px !important;

  @include for-mobile {
    border-radius: 16px 16px 0 0 !important;
  }

  &.vip {
    border-radius: 8px 8px 0 8px !important;

    @include for-mobile {
      border-radius: 8px 8px 0 0 !important;
    }
  }
}

.inputBoxOpenedFull {
  border-radius: 16px 16px 0 0 !important;

  &.vip {
    border-radius: 8px 8px 0 0 !important;
  }
}

.changeCoinFrom {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  &.exchangePage {
    margin-bottom: 16px;
  }

  .coinsList {
    left: 0;
  }
}

.name {
  font-size: $xxsFontSize;
  margin-bottom: 10px;
  color: $colorTextGray;
  display: flex;
  column-gap: 6px;
  align-items: center;

  &.repay {
    margin-bottom: 8px;
  }

  @include for-mobile {
    &.exchangeLayout {
      margin-bottom: 8px;
    }
  }
}

.openListButton {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 24px;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  &_repay {
    padding-right: 12px;
  }

  svg {
    transition: all 0.3s;
  }
}

.currency {
  display: flex;
  align-items: center;
  flex-grow: 0;
  font-size: $smallFontSize;
  text-transform: uppercase;
  color: $colorTextGray;
  margin-right: 6px;
  user-select: none;

  &.exchangePage {
    min-width: 170px;

    @include for-mobile {
      min-width: 100px;
    }
  }

  &_bigMode {
    color: $colorAccountBlack;
    font-size: $xxlFontSize;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    margin-right: 8px;

    @include for-mobile {
      font-size: 14px;
      line-height: unset;
    }
  }

  &.vip {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    min-width: auto;

    p {
      color: #fff !important;
    }
  }
}

.available {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 300;
  color: $colorAccountTextGray;
  text-transform: capitalize;

  @include for-mobile {
    display: none;
  }
}

.currencyIcon {
  margin-right: 3px;

  &_bigMode {
    margin-right: 12px;

    @include for-mobile {
      width: 24px !important;
      height: 24px !important;
      margin-right: 0 !important;
    }
  }

  @include for-mobile {
    width: 15px;
    height: 15px;
    object-fit: cover;
  }

  &.repayPage {
    width: 28px;
    height: 28px;
    object-fit: cover;
    margin-right: 8px;

    @include for-mobile {
      width: 16px;
      height: 16px;
      object-fit: cover;
      margin-right: 0;
    }
  }
}

.currencyNetwork {
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;

  &_bigMode {
    margin-left: 12px;
    line-height: 14px;
    padding: 4px 6px;

    @include for-mobile {
      font-size: 10px;
      line-height: 1.4;
      padding: 1px 6px;
      height: fit-content;
      width: fit-content;
      letter-spacing: 0;
    }
  }

  &.repayPage {
    margin-left: 0;
    font-size: 10px;
    line-height: 1.4;
    padding: 1px 6px;

    @include for-mobile {
      margin-left: 6px;
    }
  }
}

.balance {
  color: $colorTextGray;
  display: flex;
  align-items: center;
  margin-right: 12px;

  &_exchange {
    color: $colorAccountBlack;
  }

  @include for-mobile {
    column-gap: 0;
    margin-right: 0;
    color: $colorAccountBlack;
    font-size: 18px;
    font-weight: 500;
  }

  &.repay {
    margin-right: 0;
    column-gap: 6px;

    p {
      font-size: 16px;
      line-height: 1.5;
      color: $colorAccountBlack;
      font-weight: 500;

      @include for-mobile {
        font-size: 18px;
        line-height: 1.4;
      }
    }
  }

  &_desktop {
    @include for-mobile {
      display: none;
    }
  }

  &_mobile {
    display: none;

    @include for-mobile {
      display: flex;
    }
  }
}

.iconArrow {
  width: 16px;
  height: 10px;
  object-fit: cover;

  &.repayPage {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease 0s;

    svg {
      path {
        transition: all 0.3s ease 0s;
      }
    }

    &:hover {
      svg {
        path {
          stroke: $colorAccountBlueHover;
        }
      }
    }

    &:active {
      svg {
        path {
          stroke: $colorAccountBlueFocus;
        }
      }
    }
  }
}

.openedListArrow {
  transform: rotate(180deg);
}

.inputErrorValue {
  border-bottom: 1px solid $colorRedError;
}

.loader {
  position: absolute;
  width: 20px;
  left: 10px;
}

@include for-mobile-tablet-and-xs-tablet {
  .inputBox {
    width: 100%;
  }

  .changeCoinFrom {
    width: 100%;
    margin-bottom: 32px;
  }
}
