@import 'styles/variables';

.tickerName {
  font-family: Inter, serif;
  text-align: center;
  padding: 4px 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 18px;
  width: fit-content;
}
