@import 'styles/variables';

.block {
  padding-bottom: 120px;
  padding-top: 50px;
  overflow: hidden;
  background-color: white;
}

.blockWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.content {
  width: 656px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $smallTitleFontSize;
  line-height: 52px;
  color: $colorTextBlackMain;
  margin-bottom: 50px;
}

.showMore {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.showMoreBtnWrapper {
  position: relative;
  width: 200px;
}

.showMoreBtn {
  border-radius: 10px;
}

.questionsList {
  border-top: 1px solid rgba(0, 0, 0, 0.13);
}

.tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);

  p,
  li {
    font-size: $smallFontSize;
    line-height: 24px;
    color: $colorTextBlackMain;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    span {
      font-weight: 600;
    }
  }
}

.tabContent {
  max-height: 0;
  overflow: hidden;
  width: 590px;

  li {
    padding-left: 10px;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      left: 0;
      top: 10px;
      background-color: $colorTextBlackMain;
      border-radius: 50%;
    }
  }

  a {
    color: $colorBackgroundMainBlue;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    max-height: 300px;
    margin-bottom: 10px;
    object-fit: contain;
  }
}

.tabHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    img {
      path {
        opacity: 1;
      }
    }
  }

  span {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: $xxlFontSize;
    line-height: 31px;
    color: #000;
    padding: 20px 0;
    width: 610px;
  }
}

.openedTab {
  .tabContent {
    animation: open-tab ease-in 0.2s;
    max-height: 1000px;
    padding-bottom: 12px;
    color: #1d1d1d;
    opacity: 0.5;
  }

  .tabHead {
    img {
      animation: open-tab-btn ease-in 0.2s;
      transform: rotate(135deg);
    }
  }
}

.closedTab {
  .tabContent {
    animation: close-tab ease-in 0.2s;
    max-height: 0;
    padding-bottom: 0;
    color: #1d1d1d;
    opacity: 0.5;
  }

  .tabHead {
    img {
      animation: close-tab-btn ease-in 0.2s;
    }
  }
}

@keyframes open-tab-btn {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(135deg);
  }
}

@keyframes close-tab-btn {
  0% {
    transform: rotate(135deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes open-tab {
  0% {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0;
  }

  10% {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0;
  }

  100% {
    max-height: 300px;
    opacity: 0.5;
    padding-bottom: 12px;
  }
}

@keyframes close-tab {
  0% {
    max-height: 300px;
    padding-bottom: 12px;
  }

  80% {
    max-height: 0;
    padding-bottom: 0;
  }

  100% {
    max-height: 0;
    padding-bottom: 0;
  }
}

.image {
  width: 485px;
  position: absolute;
  top: 64px;
  right: -33px;

  img {
    width: 100%;
    height: 100%;
  }
}

@media (--mobile), (--tablet), (--xs-tablet) {
  .image {
    display: none;
  }

  .content {
    width: 100%;
  }

  .tabContent {
    width: 100%;
  }

  .tabHead {
    span {
      padding: 28px 0;
    }
  }
}

@media (--mobile) {
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
}
