@import 'styles/variables';

.slide {
  vertical-align: top;
  display: inline-block;
  white-space: normal;
  background: linear-gradient(152.38deg, #eff7fc 51.36%, #fff 81.88%);
  border-radius: 4px;
  padding: 30px 50px;
  width: 100%;
  transition: transform 0.3s;
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.2);
  }
}

.answer {
  display: flex;
  align-items: center;
  color: black;

  span {
    margin-left: auto;
  }
}

.avatar {
  border-radius: 50%;
}

.author {
  margin-left: 30px;
}

.name {
  font-family: 'Inter', serif;
  font-size: 16px;
  line-height: 25px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

.position {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  opacity: 0.5;
}

.firm {
  height: 56px;

  &.desktop {
    margin-left: auto;
  }
}

.mobile {
  display: none;
}

.header {
  padding-top: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2b2b39;
}

.body {
  padding-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #1d1d1d;
  opacity: 0.5;
}

@media (--mobile) {
  .slide {
    padding: 20px 10px;
    width: 100%;
  }

  .firm {
    margin-left: 0;
  }

  .body {
    padding-bottom: 30px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
