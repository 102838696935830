@import 'styles/variables';
@import 'styles/mixins';

.swiperWrapper {
  width: 100%;
  display: flex;
}

.swiperContent {
  padding: 31px 0 40px;
  width: 912px;
  overflow: hidden;
}

.title {
  margin-bottom: 30px;
  text-align: center;
}

.swiperButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 456px);
  z-index: 3;
  background: $colorBackgroundMainBlue;
  transition-duration: 0.3s;

  &:hover {
    background-color: #0f5fdc;
  }

  div {
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    border-right: 25px solid $colorGold;
  }
}

.swiperButtonNext {
  div {
    border-right: none;
    border-left: 25px solid $colorGold;
  }
}

.slides {
  margin-bottom: 30px;
  transition: left 0.3s;
  white-space: nowrap;
  position: relative;
}

.dotsWrapper {
  display: flex;
  justify-content: center;

  @include for-mobile {
    margin-top: -40px;
  }
}

.dotsWrapperInner {
  position: relative;
  display: flex;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.dotsCage,
.dotActive {
  position: absolute;
}

.dotActive {
  background: $colorGold;
  padding: 1px 0;
  transition-duration: 0.3s;
  z-index: 1;
}

.dotsCage {
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: calc(100% + 20px);
}

.dotEmpty {
  background: $colorTextC3;

  & + & {
    margin-left: 20px;
  }
}

@media (--mobile), (--xs-tablet), (--tablet) {
  .swiperWrapper {
    width: 95%;
    margin: 0 2.5%;
  }

  .swiperContent {
    width: 100%;
  }

  .swiperButton {
    display: none;
  }
}
