@import 'styles/variables';

.ltvOption {
  border-radius: 6px;
  border: 1px solid $colorBorderGray;
  color: $colorTextGray;
  padding: 0 6px;
  transition: 0.05s;

  & + & {
    margin-left: 10px;
  }
}

.ltvOptionSelected {
  border-color: $colorGold;
  color: $colorGold;
}
