@import 'styles/variables';

.noticeWrapper {
  vertical-align: middle;
  display: flex;
  position: relative;
}

.noticeText {
  font-size: 12px !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  color: $colorAccountTextGray !important;
  opacity: 0.5;
}

.notice {
  display: flex;
  align-items: center;

  &_img {
    vertical-align: top;
    cursor: pointer;
    opacity: 0.2;
    width: 10px;
    height: 10px;

    &_big {
      width: 16px;
      height: 16px;
      opacity: 0.6;

      &:hover {
        opacity: 1 !important;
      }
    }

    &:hover {
      opacity: 0.6;

      + .box {
        visibility: visible;
        width: 185px;
        height: fit-content;
      }
    }
  }
}

.box {
  position: absolute;
  z-index: 3;
  visibility: hidden;
  padding: 16px;
  left: 50%;
  white-space: normal;
  top: 15px;
  background: #fff;
  box-shadow: 5px 0 24px rgba(0, 26, 67, 0.1);
  border-radius: 5px;
  width: 0;
  height: 0;
  overflow: hidden;

  p {
    color: $colorTextBlack;
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
  }
}
