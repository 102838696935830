@import 'styles/variables';

.input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-family: 'Poppins', sans-serif;
  color: $colorTextBlackMain;
  font-size: 24px;
  line-height: 31px;
  font-weight: 600;
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: #f3f5ff;
  }
}

.input::placeholder {
  color: $colorTextBlackMain;
  opacity: 0.1;
}
