@import 'styles/variables';

.coin {
  position: relative;
  display: inline;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.base {
  position: relative;
  top: -0.5em;
  font-size: 0.7em !important;
}

.termsValue {
  .base {
    display: none;
  }
}
