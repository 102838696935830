@import 'styles/variables';
@import 'styles/mixins';

$borderColor: linear-gradient(283.45deg, #ffd37a 13.43%, #ffedd4 25.75%, #ffd37a 38.27%, #ffedd4 69.35%, #ffd37a 92.71%);
$secondaryText: #a4a4a4;
$scrollColor: #312f37;

.coinsList {
  position: absolute;
  z-index: 3;
  top: 90%;
  right: 0;
  max-width: 310px;
  background-color: #f8f9fd;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  user-select: none;
  padding-right: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: none;

  &_opened {
    display: block;
  }

  &_exchange {
    margin-top: 5px;
    padding-right: 0;
  }

  @include for-mobile {
    max-width: unset;
    right: 0;
  }

  &.vip {
    background: $colorBgVip;
  }
}

.search {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f1f3fa;
  border-radius: 8px;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;

  .searchIcon {
    margin-left: 9px;
  }

  .searchInput {
    padding: 0 10px 0 7px;
    color: $colorTextBlackMain;
    height: 100%;
    border: none;
    background-color: #f1f3fa;

    &::placeholder {
      color: $colorTextGray;
    }
  }

  &.vip {
    background: linear-gradient($colorBgVip, $colorBgVip) padding-box, $borderColor border-box;
    border: 1px solid transparent;

    .searchInput {
      padding: 0 10px 0 7px;
      color: #fff;
      height: 100%;
      border: none;
      background-color: $colorBgVip;

      &::placeholder {
        color: $colorTextGray;
      }
    }
  }
}

.searchGroup {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: $xxsFontSize;
  line-height: 140%;
  padding: 10px 16px 10px 16px;
  color: $colorTextC3;
}

.coinsListScrollbar {
  max-height: 260px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #edeff8;
    border-radius: 8px;
  }

  &.vip {
    &::-webkit-scrollbar-thumb {
      background: $scrollColor;
      border-radius: 8px;
    }
  }
}

.coinsListItem {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20px 60px 35px 1fr 25px;
  align-items: center;
  padding: 2px 16px;
  height: 40px;
  cursor: pointer;

  .checkIcon {
    margin-left: auto;
    margin-right: 10px;
    width: 14px;
  }

  &:hover {
    background: #f1f3fa;
    border-radius: 16px;
  }

  .coinFullName {
    font-weight: 500;
    font-size: $smallFontSize;
    color: $colorTextBlackMain;
    opacity: 0.8;
    line-height: 19px;
    margin-left: 3px;
  }

  .ticker {
    font-size: $xsFontSize;
    color: $colorTextBlackMain;
    margin-left: 7px;
    display: flex;

    p {
      display: inline-block;
    }
  }

  .coinName {
    font-size: $xxsFontSize;
    line-height: 19px;
    color: $colorTextGray;
    margin-left: 15px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.vip {
    .ticker {
      color: #fff;
    }

    .coinName {
      color: $secondaryText;
    }

    &:hover {
      background: #39393a;
      border-radius: 16px;
    }
  }
}
