@import 'styles/variables';
@import 'styles/mixins';

.termsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.apr {
  font-size: 12px;
  line-height: 28px;
  color: #f90;
  border: 0.3px solid #f90;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 5px 2px 5px;
  margin-right: 10px;
}

.termsItem {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & + & {
    margin-top: 7px;
  }

  @media (--mobile) {
    max-height: 28px;
  }

  @media (max-width: 410px) {
    max-height: 100%;
    flex-direction: column;
  }
}

.loader {
  position: absolute;
  width: 20px;
  left: 10px;
}

.termsName {
  display: flex;
  align-items: center;

  > p {
    font-weight: bold;
    font-size: $xsFontSize;
    line-height: 140%;
    color: #2b2b39;
    margin-right: 6px;
  }
}

.termsValue {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: $xsFontSize;
  line-height: 28px;
  color: $colorTextBlackMain;

  span {
    &.crossed {
      text-decoration: line-through;
    }

    &.highlighted {
      color: $colorGold;
    }
  }

  &__text {
    &_highlighted {
      font-weight: 700;
    }

    &_inactive {
      color: $colorTextC3;
      text-decoration: line-through;
    }

    &_space_right {
      margin-right: 10px;
    }

    @include for-mobile {
      &_hidden {
        display: none;
      }
    }
  }
}

.extraItem {
  font-size: $mediumNoteFontSize;
  margin-top: 12px;
  line-height: 28px;
}

@media (--mobile), (--tablet), (--xs-tablet) {
  .termsItem {
    & + & {
      margin-top: 10px;
    }
  }

  .termsName {
    width: 100px;
    white-space: nowrap;
  }

  .extraItem {
    line-height: 14px;
    margin-bottom: 10px;
  }
}
