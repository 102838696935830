@import 'styles/variables';

.button {
  width: 100%;
  height: 54px;
  background: $colorGold;
  box-shadow: 0 0 17px rgba(0, 26, 67, 0.07);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #f4f8fe;
  line-height: 1.19;
  transition: all 0.3s ease 0s;

  &:hover {
    background: $colorGoldActive;
  }
}

.disabled {
  background-color: $colorGrey;

  &:hover {
    background: $colorGrey;
  }
}
